input[type="file"]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: var(--chakra-colors-diamond-600);
  padding: 7px 15px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
  background: var(--chakra-colors-diamond-700);
}

input[type="file"] {
  padding: 10px;
  border: 2px dashed var(--chakra-colors-diamond-200);
}

form {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
