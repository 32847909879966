.img-wrapper {
  position: relative;
}

.img-wrapper img {
  width: 100%;
}

.img-wrapper svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
