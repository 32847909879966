.rootContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.footer {
  flex-shrink: 0;
}

.main {
  margin: 0.3em 7.3vw !important;
  flex: 1 0 auto;
}

html,
body,
#root {
  height: 100%;
  background-color: var(--chakra-colors-diamond-50);
}

.homeRoot > * {
  padding: 4vh 10vw;
  width: 100%;
  justify-content: start;
  align-items: start;
}

input:read-only {
  background-color: transparent;
  cursor: not-allowed;
  border: none;
}

@media only screen and (max-width: 1100px) {
  /* For mobile phones: */
  .main {
    margin: 0.3em 3vw !important;
  }
}
